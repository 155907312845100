import "bootstrap-icons/font/bootstrap-icons.css";
import React, { useState, useEffect, useCallback } from "react";
import capitals from "../utils/capitals.json";
import { Button, Form } from "react-bootstrap";
import axios from "axios";
import Timer from "./Timer";
import InstructionModal from "./InstructionModal";
import { getNewCountry, getRandomCountry, countries } from "../utils/util.js";
export default function Capitals() {
  const [hardGameInProgress, setHardGameInProgress] = useState(false);
  const [easyGameInProgress, setEasyGameInProgress] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const usedCountry = new Map();
  const [easyHighScore, setEasyHighScore] = useState(0);
  const [hardHighScore, setHardHighScore] = useState(0);
  const startingCountry = getNewCountry(usedCountry);

  const hardGameHandler = () => {
    setHardGameInProgress(true);
  };
  const easyGameHandler = () => {
    setEasyGameInProgress(true);
  };
  const handleShowInstructions = () => {
    setShowInstructions(true);
  };
  const handleCloseInstructions = () => {
    setShowInstructions(false);
  };
  const exitHandler = () => {
    easyGameInProgress
      ? setEasyGameInProgress(false)
      : setHardGameInProgress(false);
  };

  useEffect(() => {
    const getHighScore = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/getCapitalsGameHighScore`
        );
        setEasyHighScore(response.data.easyHighScore.score);
        setHardHighScore(response.data.hardHighScore.score);
      } catch (err) {
        console.error("Failed to fetch high scores:", err);
      }
    };
    getHighScore();
  }, [easyGameInProgress, hardGameInProgress]);

  return (
    <div className="d-flex justify-content-center align-items-center full-height-minus-navbar bg-light">
      <div className="container p-4 rounded shadow-lg bg-white">
        <div className="text-center mb-4">
          <h1 className="display-4">
            Capitals Game{" "}
            <Button
              variant="link"
              size="lg"
              className="p-0"
              title="Click for instructions"
              onClick={handleShowInstructions}
            >
              <i className="bi bi-info-circle"></i>
            </Button>
          </h1>
        </div>
        <h5>Select a difficulty level:</h5>
        <div className="d-flex mb-4">
          <Button
            variant="primary"
            size="lg"
            className="me-3"
            onClick={easyGameHandler}
            disabled={easyGameInProgress || hardGameInProgress}
          >
            Easy
          </Button>
          <Button
            variant="danger"
            size="lg"
            onClick={hardGameHandler}
            disabled={easyGameInProgress || hardGameInProgress}
          >
            Hard
          </Button>
          {(easyGameInProgress || hardGameInProgress) && (
            // <div className="mt-2">
            <Button
              className="ms-auto"
              variant="outline-danger"
              size="lg"
              onClick={exitHandler}
            >
              Quit
            </Button>
            // </div>
          )}
        </div>

        <InstructionModal
          show={showInstructions}
          handleClose={handleCloseInstructions}
        />
        <div className="mt-4">
          {hardGameInProgress && (
            <CapitalsGameHard
              hardGameInProgress={hardGameInProgress}
              setHardGameInProgress={setHardGameInProgress}
              hardHighScore={hardHighScore}
              usedCountry={usedCountry}
              startingCountry={startingCountry}
            />
          )}
          {easyGameInProgress && (
            <CapitalsGameEasy
              easyGameInProgress={easyGameInProgress}
              setEasyGameInProgress={setEasyGameInProgress}
              easyHighScore={easyHighScore}
              usedCountry={usedCountry}
              startingCountry={startingCountry}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function CapitalsGameHard({
  hardGameInProgress,
  setHardGameInProgress,
  hardHighScore,
  usedCountry,
  startingCountry,
}) {
  const [score, setScore] = useState(0);
  const [country, setCountry] = useState(startingCountry);
  const [capitalInput, setCapitalInput] = useState("");

  const handleGameOver = () => {
    const updateHighScore = async (score) => {
      try {
        if (score <= hardHighScore) return;
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/updateCapitalsGameHighScore`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              mode: "hard",
              score: score,
            }),
          }
        );
        if (!response.ok) {
          throw new Error("Failed to update high score");
        }
      } catch (err) {
        console.error("Error updating high score:", err);
      }
    };
    alert("Your score: " + score + "\nHigh score: " + hardHighScore);
    updateHighScore(score);
  };

  const skipHandler = (e) => {
    e.preventDefault();
    setCountry(getNewCountry(usedCountry));
    setCapitalInput("");
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    const inputCapital = e.target.value;
    setCapitalInput(inputCapital);

    if (inputCapital.toLowerCase() === capitals[country].toLowerCase()) {
      setScore((prevScore) => prevScore + 1);
      setCapitalInput("");
      setCountry(getNewCountry(usedCountry));
    }
  };

  return (
    <div>
      <Timer
        gameInProgress={hardGameInProgress}
        setGameInProgress={setHardGameInProgress}
        handleGameOver={handleGameOver}
      />
      <h5>High Score: {hardHighScore}</h5>
      <h5>Score: {score}</h5>
      {hardGameInProgress && (
        <div>
          <Form onSubmit={skipHandler}>
            <Form.Group className="mb-3" controlId="country">
              <Form.Label className="fs-1">{country}</Form.Label>
              <Form.Control
                type="string"
                name="capital"
                value={capitalInput}
                onChange={handleInputChange}
                autoFocus={true}
              />
            </Form.Group>

            <Button variant="secondary" type="submit">
              Skip
            </Button>
          </Form>
        </div>
      )}
    </div>
  );
}

function CapitalsGameEasy({
  easyGameInProgress,
  setEasyGameInProgress,
  easyHighScore,
  usedCountry,
  startingCountry,
}) {
  const [score, setScore] = useState(0);
  const [country, setCountry] = useState(startingCountry);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(""); // State for the selected radio button
  const handleGameOver = useCallback(() => {
    const updateHighScore = async (score) => {
      try {
        if (score <= easyHighScore) return;
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/updateCapitalsGameHighScore`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              mode: "easy",
              score: score,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to update high score");
        }
      } catch (err) {
        console.error("Error updating high score:", err);
      }
    };
    alert("Your score: " + score + "\nHigh score: " + easyHighScore);

    updateHighScore(score);
  }, [score, easyHighScore]);

  useEffect(() => {
    if (usedCountry.size === 194) {
      setEasyGameInProgress(false);
      handleGameOver();
    }
    let newOptions = [capitals[country]];
    for (let i = 0; i < 3; i++) {
      let option;
      do {
        option = getRandomCountry(countries);
      } while (newOptions.includes(capitals[option]));
      Math.random() < 0.5
        ? newOptions.push(capitals[option])
        : newOptions.unshift(capitals[option]);
    }
    setOptions(newOptions);
    setSelectedOption("");
  }, [country, handleGameOver, setEasyGameInProgress, usedCountry]);

  const handleOptionChange = (e) => {
    const currentOption = e.target.value;
    setSelectedOption(currentOption);
    if (currentOption === capitals[country]) {
      setScore((prevScore) => prevScore + 4);
    } else {
      setScore((prevScore) => prevScore - 1);
    }
    setSelectedOption("");
    setCountry(getNewCountry(usedCountry));
  };
  const skipHandler = (e) => {
    e.preventDefault();
    setCountry(getNewCountry(usedCountry));
  };
  return (
    <div>
      <Timer
        gameInProgress={easyGameInProgress}
        setGameInProgress={setEasyGameInProgress}
        handleGameOver={handleGameOver}
      />
      <h5>High Score: {easyHighScore}</h5>
      <h5>Score: {score}</h5>
      {easyGameInProgress && (
        <div>
          <Form>
            <Form.Group className="mb-3" controlId="country">
              <Form.Label className="fs-1">{country}</Form.Label>
              {options.map((option, index) => (
                <Form.Check
                  key={index}
                  className="fs-5"
                  name="options"
                  type="radio"
                  label={option}
                  value={option}
                  id={`radio-${index}`}
                  onChange={handleOptionChange}
                  checked={selectedOption === option}
                />
              ))}
            </Form.Group>

            <Button variant="secondary" onClick={skipHandler}>
              Skip
            </Button>
          </Form>
        </div>
      )}
    </div>
  );
}
