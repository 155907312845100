import capitals from "./capitals.json";

export const countries = Object.keys(capitals);

export function getRandomCountry(countries) {
  return countries[Math.floor(Math.random() * countries.length)];
}

export function getNewCountry(usedCountry) {
  let newCountry;
  do {
    newCountry = getRandomCountry(countries);
  } while (usedCountry.get(newCountry));
  usedCountry.set(newCountry, 1);
  return newCountry;
}
