import React from "react";
import worldLogo from "../assets/world.jpeg";

export default function Home() {
  return (
    <div className="home-container d-flex justify-content-center align-items-center text-center full-height-minus-navbar bg-light">
      <div className="p-5 rounded shadow-lg bg-white">
        <h1 className="display-4 mb-4">Welcome to Geography Dash!</h1>
        <p className="lead">
          Engage in a series of fun and educational games designed to test and
          improve your geography knowledge. Are you ready for the challenge?
        </p>
        <img src={worldLogo} alt="world" />
        <br />
        <button className="btn btn-primary btn-lg mt-4">
          <a className="nav-link" href="/capitals">
            Capitals Game
          </a>
        </button>
      </div>
    </div>
  );
}
